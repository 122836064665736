@import "../../scss/all";

div.view-controls {
  @include panel-border-shadow;
  color: $text-color;
  background: $bg-color;
  padding: 10px 13px;
  font-weight: 300;
  font-size: 12px;
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-row-gap: 5px;
  grid-template-rows: auto;

  & .view-controls__control {
    cursor: pointer;
  }

  & .view-controls__label {
    align-self: center;
    justify-self: left;
    user-select: none;
    cursor: pointer;
  }

  & .view-controls__label * {
    cursor: inherit;
  }
  
  @include media-small-screens {
    grid-template-columns: 25px 1fr 25px 1fr;
  }
}
