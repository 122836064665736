@import "../scss/all";

$left-offest: 13px;

div.panels {
  position: absolute;
  top: 0px;
  left: $left-offest;
  z-index: 1;
  height: fit-content;
  max-height: 50%;
  width: fit-content;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  
  div.panels__header {
    @include panel-border-shadow;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom: 2px solid $button-color;

    background-color: $bg-color-secondary;
    color: $text-color;
    font-size: 16px;
    padding: 10px 13px 8px;
  }

  @include media-small-screens {
    width: calc(100% - #{$left-offest} * 2);
  }
}
