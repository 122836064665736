$bg-color: #142D57;
$bg-color-secondary: #143f71;

$border-color: #000000;
$text-color: #ffffff;
$text-color-secondary: #F2EC60;
$icon-color: #068AF6;
$icon-color-active: #ffffff;

$button-color: #44A0FD;
$button-color-hover: #F2EC60;

$slider-rail-color: #E8EAF0;
$slider-track-color: #0786EB;
$slider-handle-color: #ffffff;