@import "../../scss/mixins";

.page-layout-container {
  position: relative;
 

  & .slider-container {
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left:0;
    right: 0;
  }

  @include media-small-screens {
    min-width: 100%;
  }
  
  canvas {
    background-image: radial-gradient(circle at center, #2c527f 0%, #143F71 25%, #142D57 50%, #102446);
  }
}
