@mixin button-override {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

@mixin icon-button-override {
  @include button-override;
  border-radius: 50%;

  overflow: hidden;
}

@mixin panel-border-shadow {
  box-shadow: 0px 13px 70px #00000030;
  border: 1px solid $border-color;
  border-radius: 3px;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin media-small-screens {
  @media screen and (max-width: 600px) { @content; }
}
