@import "../../scss/all";

div.control-panel {
  @include panel-border-shadow;
  background-color: $bg-color;
  color: $text-color;
  height: auto;
  width: 350px;

  div.control-panel__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 13px;
    font-size: 12px;

    span.title {
      font-size: 16px;
      display: inline-block;
      color: $text-color-secondary;
      margin-right: auto
    }

    button {
      @include button-override;
      color: $text-color;
      font-size: inherit;
      .icon {
        display: inline-block;
        font-size: 22px;
        width: 22px;
        height: 22px;
      }
    }
  }

  @include media-small-screens {
    width: 100%;
  }
}