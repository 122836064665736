$background-color: #f6f6f6;
$text-color: #143f71;
$opacity-hover: 0.9;
$opacity-disabled: 0.5;

.cameraViewControls {
    position: fixed;
    bottom: 10px;
    right: 10px;

    & button {
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: $background-color;
      color: $text-color;
      text-transform: uppercase;
      width: 144px;
      height: 24px;
      border-radius: 10px;

      &:hover {
        opacity: $opacity-hover;
      }
      &:disabled {
        opacity: $opacity-disabled;
        cursor: not-allowed;
      }
    }

    @media screen and (max-height: 440px){
      & button {
        height: 14px;
        font-size: 10px;
        border-radius: 5px;
      }
    }
}