$background-color-active: #f6f6f6;
$background-color-inactive: #f6f6f68f;
$text-color: #143f71;
$opacity-hover: 0.9;
$opacity-disabled: 0.5;

.controlPanel {
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;

    .active {
      background-color: $background-color-active;
    }

    .inactive {
      background-color: $background-color-inactive;
    }

    & div {
      display: flex;
      align-items: center;
      padding: 10px;
      margin: 10px 0px 10px 10px;
      background-color: #0000005f;
    }

    & button {
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: $text-color;
      text-transform: uppercase;
      width: 144px;
      height: 24px;
      border-radius: 10px;
      margin-left: 10px;

      &:hover {
        opacity: $opacity-hover;
      }
      &:disabled {
        opacity: $opacity-disabled;
        cursor: not-allowed;
      }
    }

    @media screen and (max-height: 440px){
      & button {
        height: 14px;
        font-size: 10px;
        border-radius: 5px;
      }
    }
}