@import "../../scss/all";

div.file-panel {
  background-color: transparent;
  max-height: 340px;
  overflow: auto;
  padding: 13px;

  & div.file-panel__entity {
    margin-bottom: 16px;

    p.file-panel__entity-name {
      width: 100%;
      font-size: 11px;
      font-weight: bold;
      color: $text-color;
      cursor: pointer;
      letter-spacing: 0.5px;
      margin: 4px 0px;
      @include ellipsis;


      &.selected {
        color: $button-color-hover;
      }
    }

    div.file-panel__controls {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div.rc-slider {
        flex: 1;

        .rc-slider-rail, .rc-slider-track, .rc-slider-step {
          height: 6px;
        }
  
        .rc-slider-rail {
          background-color: $slider-rail-color;
        }
  
        .rc-slider-track {
          background-color: $slider-track-color;
        }
    
        .rc-slider-handle {
          box-shadow: inset 2px 2px 5px #FFFFFF, 2px 2px 5px #8994A94D;
          border: none;
          color: $slider-handle-color;
          margin-top: -3px;
          width: 12px;
          height: 12px;
        }
  
      }

      div.file-panel__opacity-buttons {
        @include flex-center;
        flex-direction: column;
        width: 22px;
        height: 22px;
        margin: 0px 12px 0px 8px;

        button {
          @include icon-button-override;
          font-size: 20px;
          color: $icon-color-active;
          overflow: hidden;
          &:not(:disabled):hover, &:not(:disabled):active {
            color: $button-color-hover;
            transform: scale(1.25);
          }
        }

        @include media-small-screens {
          visibility: hidden;
          width: 0px;
          pointer-events: none;
        }
      }
    }
  }

  & div.file-panel__upload-container {
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    label {
      @include flex-center;
      margin-right: auto;
      width: 22px;
      height: 22px;
      color: $button-color;
      font-size: 22px;
      border-radius: 50%;
      cursor: pointer;
      
      & input {
        display: none;
      }
    }

  }
  & button.icon {
    @include icon-button-override;
    color: $icon-color;
    width: 22px;
    height: 22px;
    margin-right: 4px;

    &:last-of-type {
      margin-right: 0px;
    }

    &.active {
      color: $icon-color-active;
    }
  }
}
