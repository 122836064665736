$stepper-color1: #f6f6f6;
$stepper-color2: #143f71;
$stepper-color3: #fff000;
$dot-radius: 0.3vw;
$dot-diameter: $dot-radius * 2;
$opacity-hover: 0.9;
$opacity-disabled: 0.5;
$stepper-width: 80vw;

$dot-radius-small-screen: 0.8vw;
$dot-diameter-small-screen: $dot-radius-small-screen * 2;



@mixin center-absolute($moveY: 0) {
  position: absolute;
  right: 50%;
  transform: translate(50%, $moveY);
}

@mixin button-override {
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

div.stepper__container {
  @include center-absolute;
  letter-spacing: 1.1px;
  color: $stepper-color1;
  font-weight: 300;
  font-size: 12px;
  bottom: 6vh;
  width: fit-content;
  counter-reset: dot-count -1;
  display: grid;
  row-gap: 6vh;
  width: $stepper-width;

  & .stepper__scale-row {
    white-space: pre;
    display: flex;
    justify-content: space-between;
    
    /* Dot styles */
    & .stepper__dot {
      display: inline-block;
      position: relative;
      width: $dot-diameter;
      height: $dot-diameter;
      border: 0px solid transparent;
      border-radius: 50%;
      background-color: $stepper-color1;
      cursor: pointer;
      counter-increment: dot-count;

      &:last-child {
        margin-right: 0px;
      }

      &:last-child:after {
        content: " ";
        width: $stepper-width - $dot-diameter;
        height: 1px;
        background-color: $stepper-color1;
        display: inline-block;
        position: absolute;
        bottom: $dot-radius;
        right: $dot-diameter;
        pointer-events: none;
      }

      &:before {
        @include center-absolute(-27px);
        /* Setting some clicking area */
        display: inline-flex;
        border-radius: 50%;
        min-width: 14px;
        min-height: 14px;
        justify-content: center;
        align-items: center;
        padding: 2px;
        content: counter(dot-count);
      }

      &.active:before {
        font-weight: 600;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      
    }

    @media screen and (max-width: 768px) {
        & .stepper__dot {
          width: $dot-diameter-small-screen;
          height: $dot-diameter-small-screen;

        }
      }

    &.reversed .stepper__dot:before {
      @include center-absolute(18px);
    }

    /* ActiveMarker styles */
    & .stepper__active-marker {
      @include center-absolute(12px);
      display: inline-block;
      width: 3vh;
      height: 3.5vh;
      max-width: 14px;
      max-height: 16px;
      background-color: $stepper-color3;
      clip-path: polygon(50% 0, 100% 100%, 0 100%);
      pointer-events: none;
    }

    &.reversed .stepper__active-marker {
      @include center-absolute(-22px);
      clip-path: polygon(0 0, 100% 0, 50% 100%);
    }
  }

  /* Controls styles */
  & .stepper__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    & .stepper__controls-btn-play {
      @include button-override;
      background-color: $stepper-color1;
      color: $stepper-color2;
      text-transform: uppercase;
      margin: 0 20px;
      width: 72px;
      height: 24px;
      border-radius: 10px;

      &:hover {
        opacity: $opacity-hover;
      }
      &:disabled {
        opacity: $opacity-disabled;
        cursor: not-allowed;
      }
    }

    @media screen and (max-height: 440px){
      & .stepper__controls-btn-play {
        height: 14px;
        font-size: 10px;
        border-radius: 5px;
      }
    }

    & .stepper__controls-btn-prev,
    & .stepper__controls-btn-next {
      @include button-override;
      color: $stepper-color1;
      background: transparent;
      border-radius: 50%;

      &:active {
        font-weight: 400;
        opacity: $opacity-hover;
      }
      &:disabled {
        opacity: $opacity-disabled;
      }
    }
  }
}

