@import "../../scss/all";
$col-1-w: 60px;
$col-gap: 12px;
$row-gap: 10px;

div.panel-measurement {
  background-color: transparent;
  max-height: 340px;
  overflow: auto;
  padding: 13px;
  font-size: 12px;

  div.panel-measurement__data {
    display: grid;
    grid-template-columns: $col-1-w repeat(3, 1fr);
    row-gap: $row-gap;
    column-gap: $col-gap;
    padding-bottom: $row-gap;

    & .label {
      text-align: right;
    }
  }

  div.panel-measurement__footer {
    display: grid;
    grid-template-columns: $col-1-w 3fr;
    column-gap: $col-gap;
    margin-top: $row-gap;

    & .label {
      text-align: right;
    }
  }

  div.panel-measurement_empty {
    @include flex-center;
    margin: 20px 0;
  }
}